<template>
  <div class="footer">
    <div class="container">
        <ul class="flex-between">
            <li v-for="(item, id) in scanList" :key="id">
                <img :src="item.url" alt="">
                <div style="margin-top: 16px;margin-bottom: 10px;">{{ item.title1 }}</div>
                <div style="height: 16px;">{{ item.title2 }}</div>
            </li>
        </ul>
    </div>
    <div class="company-desc">
        <!-- <p >技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术支持技术 支持技术支持技术支持技术支持技术支</p> -->
        <p >苏ICP备18029497号-12</p>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import Gongzhonghao from './../assets/images/home/gongzhonghao.webp';
import Miniprogram from './../assets/images/home/minprogram.png';
import Shipinhao from './../assets/images/home/shipinhao.webp';
import Douyin from './../assets/images/home/douyin.webp';
import Redbook from './../assets/images/home/redbook.webp';
import Tmall from './../assets/images/home/tmall.webp';
const scanList = ref([{
    url: Gongzhonghao,
    title1: '微信公众号',
    title2: ''
}, {
    url: Miniprogram,
    title1: '极赫小程序',
    title2: ''
}, {
    url: Shipinhao,
    title1: '视频号小店',
    title2: ''
}, {
    url: Douyin,
    title1: '前往抖音',
    title2: '搜索关注@极赫'
}, {
    url: Redbook,
    title1: '前往小红书',
    title2: '搜索关注@极赫'
}, {
    url: Tmall,
    title1: '前往天猫 ',
    title2: '搜索关注@极赫'
}])
</script>

<style scoped lang="scss">
.footer{
    background: #3C3D3F;
    width: 100%;
    padding-top: 80px;
    padding-bottom: 56px;
    .container{
        width: 1200px;
        margin: 0 auto;
    }
    ul{
        li{
            &>img{
                width: 100px;
                height: 100px;
                display: block;
                margin: 0 auto;
            }
            div{
                font-size: 16px;
                line-height: 16px;
                color: rgba(255, 255, 255, 0.5);
                text-align: center;
            }
        }
    }
    .company-desc{
        margin: 40px auto 0;
        width: 842px;
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
        line-height: 24px;
        text-align: center;
    }
}
</style>
